import dateFormat from "@/filters/date.filter";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DownloadRequestParam } from "@/models/interface/download";
import store from "@/store";
import { Moment } from "moment";

export class AssetTransferMapper {
  static mapDownloadParams(
    params: RequestQueryParams,
    {
      documentNumber,
      sendDate,
      receiveDate,
      status,
      locationSource,
      destination,
      unitCode,
    }: Partial<{
      documentNumber?: string;
      sendDate: Moment | null;
      receiveDate: Moment | null;
      status?: string;
      locationSource?: string;
      destination?: string;
      unitCode?: string;
    }>
  ): DownloadRequestParam {
    const DEFAULT = "All";
    const company = store.state["authStore"].authData.companyName || "";

    const arrayParams = [
      company,
      documentNumber || DEFAULT,
      sendDate ? dateFormat(sendDate) : DEFAULT,
      receiveDate ? dateFormat(receiveDate) : DEFAULT,
      status || DEFAULT,
      locationSource || DEFAULT,
      destination || DEFAULT,
      unitCode || DEFAULT,
    ];

    return {
      ...params,
      params: arrayParams.join(","),
    };
  }
}
