














import {
  useFindMasterType,
  useLocalFilter,
  useMapMasterTypeToOptionAlt,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectAssetTransferStatus extends Vue {
  useLocalFilter = useLocalFilter;

  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option[] = [];
  loading = false;

  created(): void {
    this.fetchData();
  }

  emits(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  fetchData(): void {
    this.loading = true;
    useFindMasterType("ASSET_TRANSFER_STATUS")
      .then(res => {
        this.options = useMapMasterTypeToOptionAlt(res);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
