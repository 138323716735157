










































































































































import { SearchBuilder } from "@/builder";
import SelectAssetTransfer from "@/components/custom/select/SelectAssetTransfer.vue";
import SelectAssetTransferStatus from "@/components/custom/select/SelectAssetTransferStatus.vue";
import SelectRack from "@/components/custom/select/SelectRack.vue";
import SelectUnitCode from "@/components/custom/select/SelectUnitCode.vue";
import dateFormat from "@/filters/date.filter";
import { toTitlecase } from "@/helpers/common";
import { APagination, useBlob, useDate } from "@/hooks";
import { AssetTransferMapper } from "@/mapper/AssetTransferMapper.mapper";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { AssetTransferResponseDto } from "@/models/interface/asset-transfer";
import { assetTransferService } from "@/services/asset-transfer.service";
import { LabelInValue } from "@/types";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";

interface Filter {
  documentNumber?: LabelInValue;
  sentDate: Moment | null;
  receivedDate: Moment | null;
  status?: string;
  locationSource?: LabelInValue;
  destination?: LabelInValue;
  unitCode?: LabelInValue;
}

@Component({
  components: {
    SelectAssetTransferStatus,
    SelectRack,
    SelectUnitCode,
    SelectAssetTransfer,
  },
})
export default class AssetTransfer extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Ref("formRef")
  formRef!: FormModel;

  defaultFormData: Filter = {
    destination: undefined,
    documentNumber: undefined,
    locationSource: undefined,
    receivedDate: null,
    sentDate: null,
    status: undefined,
    unitCode: undefined,
  };

  formData: Filter = { ...this.defaultFormData };

  columns = [
    {
      title: this.$t("lbl_document_number"),
      dataIndex: "documentNo",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_sent_date"),
      dataIndex: "date",
      customRender: (text: string) => dateFormat(text),
    },
    {
      title: this.$t("lbl_received_date"),
      dataIndex: "receiveDate",
      customRender: (text: string) => dateFormat(text),
    },
    {
      title: this.$t("lbl_location_source"),
      dataIndex: "from",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_destination"),
      dataIndex: "to",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_created_by"),
      dataIndex: "createdBy",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_received_by"),
      dataIndex: "receiveBy",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      customRender: (text: string) => toTitlecase(text) || "-",
    },
    {
      title: this.$t("lbl_action"),
      scopedSlots: { customRender: "action" },
    },
  ];

  loading = {
    find: false,
    download: false,
  };

  pagination = {
    page: +this.$route?.query.p || DEFAULT_PAGE,
    limit: +this.$route?.query.l || DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
      this.limit = DEFAULT_PAGE_SIZE;
    },
  };

  dataSource: Pagination<AssetTransferResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  created(): void {
    this.fetchAssetTransfers(this.buildQueryParams());
  }

  fetchAssetTransfers(
    params: RequestQueryParams = new RequestQueryParams()
  ): void {
    this.loading.find = true;
    assetTransferService
      .listAssetTransfers(params)
      .then(res => {
        this.dataSource = res;
      })
      .finally(() => (this.loading.find = false));
  }

  buildQueryParams(): RequestQueryParams {
    const { toStartDay, toEndDay } = useDate();

    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const queries: string[] = [];

    if (this.formData.documentNumber?.label) {
      queries.push(
        builder.push(["documentNo", this.formData.documentNumber.label]).build()
      );
    }

    if (this.formData.sentDate) {
      const startDay = toStartDay(this.formData.sentDate).format();
      const endDay = toEndDay(this.formData.sentDate).format();

      queries.push(builder.push(["date", startDay], { het: true }).build());
      queries.push(builder.push(["date", endDay], { let: true }).build());
    }

    if (this.formData.receivedDate) {
      const startDay = toStartDay(this.formData.receivedDate).format();
      const endDay = toEndDay(this.formData.receivedDate).format();

      queries.push(
        builder.push(["receiveDate", startDay], { het: true }).build()
      );
      queries.push(
        builder.push(["receiveDate", endDay], { let: true }).build()
      );
    }

    if (this.formData.status) {
      queries.push(builder.push(["status", this.formData.status]).build());
    }

    if (this.formData.locationSource) {
      queries.push(
        builder.push(["fromId", this.formData.locationSource.key]).build()
      );
    }

    if (this.formData.destination) {
      queries.push(
        builder.push(["toId", this.formData.destination.key]).build()
      );
    }

    if (this.formData.unitCode) {
      queries.push(
        builder
          .push(["unitCode", this.formData.unitCode.label], { like: "both" })
          .build()
      );
    }

    params.search = queries.join(builder.AND);
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;
    params.sorts = "documentNo:desc";

    return params;
  }

  onChangeTable(pagination: APagination): void {
    const { current, pageSize } = pagination;

    const hasNextPage = pageSize === this.pagination.limit;
    this.pagination.page = hasNextPage ? current : DEFAULT_PAGE;
    this.pagination.limit = pageSize;

    const params = this.buildQueryParams();
    this.fetchAssetTransfers(params);
  }

  handleReset(): void {
    this.pagination.reset();
    this.formData = { ...this.defaultFormData };

    this.fetchAssetTransfers(this.buildQueryParams());
  }

  handleFind(): void {
    this.pagination.reset();
    this.fetchAssetTransfers(this.buildQueryParams());
  }

  handleDownload(): void {
    const { toDownload } = useBlob();

    this.loading.download = true;
    assetTransferService
      .downloadListAssetTransfers(
        AssetTransferMapper.mapDownloadParams(this.buildQueryParams(), {
          documentNumber: this.formData.documentNumber?.label,
          destination: this.formData.destination?.label,
          locationSource: this.formData.locationSource?.label,
          receiveDate: this.formData.receivedDate,
          sendDate: this.formData.sentDate,
          status: this.formData.status,
          unitCode: this.formData.unitCode?.label,
        })
      )
      .then(res => {
        toDownload(res, "asset_transfers.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }
}
