



















import Select from "@/components/custom/select/Select.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useAsset } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_PAGE, ONE } from "@/models/constant/global.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { AssetResponseDto } from "@/models/interface/master-asset";
import { LabelInValue } from "@/types";
import { LabelInValueUtils } from "@/utils/LabelInValueUtils";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectUnitCode extends Vue {
  @Prop({ required: true, default: undefined })
  value!: string | LabelInValue | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  loading = false;
  options: Array<Option<AssetResponseDto>> = [];

  pagination = {
    page: DEFAULT_PAGE || 0,
    search: "",
  };

  allLoaded = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions(this.buildParams());
  }

  fetchOptions(params: RequestQueryParamsModel): void {
    const { findAllAsset, toOptionsNew } = useAsset();
    this.loading = true;
    findAllAsset(params)
      .then(res => {
        this.allLoaded = res.currentPage + ONE === res.totalPages;
        const copy = [...this.options];
        this.options = [...copy, ...toOptionsNew(res.data)];
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e?: string | LabelInValue): void {
    if (!e) {
      this.onSearch();
    }

    let copy: string | LabelInValue | undefined = LabelInValueUtils.trim(e);
    this.$emit("input", copy);
    this.$emit("change", copy);
    this.$emit("update:meta", this.findOption(copy));
  }

  onSearch(val = ""): void {
    const { filterBy } = useAsset();
    this.resetState();
    const params = new RequestQueryParams();
    params.search = filterBy({ unitCode: val });
    this.fetchOptions(params);
  }

  findOption(
    value?: string | LabelInValue
  ): Option<AssetResponseDto> | undefined {
    if (!value) {
      return;
    }

    if (typeof value === "string") {
      return this.options.find(e => value === e.value);
    } else {
      return this.options.find(e => value.key === e.key);
    }
  }

  buildParams(): RequestQueryParamsModel {
    const params = new RequestQueryParams();
    const { page, search } = this.pagination;
    params.page = page - ONE;
    params.search = search;
    return params;
  }

  onScrollEnd(): void {
    if (this.loading || this.allLoaded) return;
    this.pagination.page += ONE;
    this.fetchOptions(this.buildParams());
  }

  resetState(): void {
    this.pagination.page = ONE;
    this.options = [];
    this.allLoaded = false;
  }
}
