

















import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AssetTransferResponseDto } from "@/models/interface/asset-transfer";
import { assetTransferService } from "@/services/asset-transfer.service";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectAssetTransfer extends Vue {
  @Prop({ required: false, default: undefined })
  value!: string | LabelInValue | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  options: Option<AssetTransferResponseDto>[] = [];

  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    this.loading = true;
    assetTransferService
      .listAssetTransfers(params)
      .then(res => {
        this.options = res.data.map(assetTransfer => ({
          key: assetTransfer.id,
          label: assetTransfer.documentNo,
          value: assetTransfer.id,
          meta: assetTransfer,
        }));
      })
      .finally(() => (this.loading = false));
  }

  onChange(e: string | LabelInValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();

    if (val) {
      params.search = builder
        .push(["documentNo", val], { like: "both" })
        .build();
    }

    this.fetchOptions(params);
  }

  findOption(
    value?: string | LabelInValue
  ): Option<AssetTransferResponseDto> | undefined {
    if (!value) {
      return;
    }

    if (typeof value === "string") {
      return this.options.find(e => value === e.value);
    } else {
      return this.options.find(e => value.key === e.key);
    }
  }
}
