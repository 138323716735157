var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_asset_transfer") } },
    [
      _c(
        "a-form-model",
        {
          ref: "formRef",
          attrs: {
            model: _vm.formData,
            "label-col": { span: 8 },
            "wrapper-col": { span: 14 },
            layout: "vertical",
            "label-align": "left",
            "data-testid": "list-asset-transfer-form"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_document_number"),
                        prop: "documentNumber"
                      }
                    },
                    [
                      _c("SelectAssetTransfer", {
                        attrs: {
                          placeholder: _vm.$t("lbl_document_number"),
                          "label-in-value": ""
                        },
                        model: {
                          value: _vm.formData.documentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "documentNumber", $$v)
                          },
                          expression: "formData.documentNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_sent_date"),
                        prop: "sentDate"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                        model: {
                          value: _vm.formData.sentDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "sentDate", $$v)
                          },
                          expression: "formData.sentDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_received_date"),
                        prop: "receivedDate"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                        model: {
                          value: _vm.formData.receivedDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "receivedDate", $$v)
                          },
                          expression: "formData.receivedDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_status"), prop: "status" } },
                    [
                      _c("SelectAssetTransferStatus", {
                        model: {
                          value: _vm.formData.status,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "status", $$v)
                          },
                          expression: "formData.status"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_location_source"),
                        prop: "locationSource"
                      }
                    },
                    [
                      _c("SelectRack", {
                        attrs: { "value-as": "id" },
                        model: {
                          value: _vm.formData.locationSource,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "locationSource", $$v)
                          },
                          expression: "formData.locationSource"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_destination"),
                        prop: "destination"
                      }
                    },
                    [
                      _c("SelectRack", {
                        attrs: { "value-as": "id" },
                        model: {
                          value: _vm.formData.destination,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "destination", $$v)
                          },
                          expression: "formData.destination"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_unit_code"),
                        prop: "unitCode"
                      }
                    },
                    [
                      _c("SelectUnitCode", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.formData.unitCode,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "unitCode", $$v)
                          },
                          expression: "formData.unitCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    loading: _vm.loading.find,
                                    "data-testid":
                                      "list-asset-transfer-btn-reset"
                                  },
                                  on: { click: _vm.handleReset }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading.find,
                                    "data-testid":
                                      "list-asset-transfer-btn-find"
                                  },
                                  on: { click: _vm.handleFind }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_find")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.$can("create", "asset-transfer")
                        ? _c(
                            "a-col",
                            { attrs: { span: 12, align: "end" } },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: { name: "create.asset.transfer" }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    { attrs: { type: "primary" } },
                                    [_vm._v(_vm._s(_vm.$t("lbl_create_new")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      size: "small",
                      "row-key": "id",
                      dataSource: _vm.dataSource.data,
                      columns: _vm.columns,
                      loading: _vm.loading.find,
                      pagination: {
                        showTotal: function() {
                          return _vm.$t("lbl_total_items", {
                            total: _vm.dataSource.totalElements
                          })
                        },
                        total: _vm.dataSource.totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                        current: _vm.pagination.page,
                        pageSize: _vm.pagination.limit
                      },
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      scroll: { x: 1000 },
                      "data-testid": "list-asset-transfer-table"
                    },
                    on: { change: _vm.onChangeTable },
                    scopedSlots: _vm._u([
                      {
                        key: "action",
                        fn: function(_, record) {
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "asset.transfer.detail",
                                    params: { id: record.id }
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  { attrs: { icon: "eye", type: "link" } },
                                  [_vm._v(_vm._s(_vm.$t("lbl_view")))]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "data-testid": "list-asset-transfer-btn-download",
                        loading: _vm.loading.download
                      },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }