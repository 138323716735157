import { LabelInValue } from "@/types";

export class LabelInValueUtils {
  static trim(
    value: string | LabelInValue | undefined
  ): string | LabelInValue | undefined {
    if (typeof value === "string") {
      return value;
    } else if (value && typeof value === "object" && "key" in value) {
      return {
        key: value.key,
        label: value.label.trim(),
      };
    }
    return undefined;
  }
}
